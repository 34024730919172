import React from 'react'
import Navigation from '../components/navigation'
import Layout from '../layouts'

const SecondPage = () => (
  <Layout>
    <Navigation />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
  </Layout>
)

export default SecondPage
